<template>
  <v-menu
    v-model="isRoiSelectorOpened"
    :close-on-content-click="false"
    :min-width="450"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn fab x-small color="white" v-bind="attrs" v-on="on">
        <v-icon> fa fa-cog </v-icon>
      </v-btn>
    </template>
    <div class="roi-selector">
      <v-btn
        outlined
        small
        color="primary"
        class="roi-selector__add-btn"
        @click="roiDialog = true"
      >
        <v-icon> fas fa-plus </v-icon> Add Polygon ROI
      </v-btn>
      <div>
        <div class="roi-selector__headers">
          <div>Actions</div>
          <div>Name</div>
          <div>Date</div>
          <div>Type</div>
        </div>
        <div :class="{ 'd-flex justify-center': isFetchingRois || noRoiData }">
          <EvercamLoadingAnimation v-if="isFetchingRois" size="Md" />
          <span v-else-if="noRoiData">No Data.</span>
          <div v-else>
            <div
              v-for="item in rois"
              :key="item.id"
              class="roi-selector__item"
              :class="{
                'roi-selector__item--selected': isSelectedRoi(item),
              }"
              @click="selectRoi(item)"
            >
              <div class="d-flex justify-start align-center">
                <v-btn icon small @click.stop="editRoi(item)">
                  <v-icon> fas fa-pencil-alt </v-icon>
                </v-btn>
                <v-btn icon small @click.stop="deleteRoi(item)">
                  <v-icon> fas fa-trash </v-icon>
                </v-btn>
              </div>
              <div>{{ item.name }}</div>
              <div>{{ item.fromDate }}</div>
              <div>{{ item.roiType }}</div>
            </div>
          </div>
        </div>
      </div>
      <RoiFormDialog
        v-model="roiDialog"
        :edited-roi="editedRoi"
        @upsert-roi="onRoiUpsert"
      />
    </div>
  </v-menu>
</template>

<script>
import RoiFormDialog from "@/components/siteAnalytics/RoiFormDialog"

import { AiApi } from "@evercam/shared/api/aiApi"

import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation.vue"

export default {
  name: "RoiSelector",
  components: { RoiFormDialog, EvercamLoadingAnimation },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    isFetchingRois: {
      type: Boolean,
      default: false,
    },
    rois: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isRoiSelectorOpened: false,
      roiDialog: false,
      editedRoi: null,
    }
  },
  computed: {
    ...mapStores(useSiteAnalyticsStore, useAccountStore),
    noRoiData() {
      return !this.rois?.length
    },
    selectedRoi: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("select-roi", value)
      },
    },
  },
  watch: {
    "siteAnalyticsStore.selectedCamera": {
      async handler(camera, oldCamera) {
        if (camera?.exid && camera?.exid !== oldCamera?.exid) {
          this.$emit("fetch-rois")
        }
      },
      deep: true,
      immediate: true,
    },
    roiDialog(value) {
      if (!value) {
        this.editedRoi = null
      }
    },
  },
  methods: {
    selectRoi(roi) {
      this.selectedRoi = !this.isSelectedRoi(roi) ? roi : null
    },
    isSelectedRoi(roi) {
      return roi?.id === this.selectedRoi?.id
    },
    editRoi(roi) {
      this.editedRoi = roi
      this.roiDialog = true
    },
    async deleteRoi(roi) {
      const confirmed = await this.$confirmDialog.open({
        title: "Delete ROI",
        message: `Are you sure you want to delete the ROI: <strong>${roi.name}</strong>?`,
      })
      if (!confirmed) {
        return
      }
      try {
        await AiApi.roi.deleteROI({
          id: roi.id,
          updatedBy: this.accountStore.email,
        })
        this.$emit("fetch-rois")
        if (this.isSelectedRoi(roi)) {
          this.selectedRoi = null
        }
      } catch (error) {
        console.error(error)
      }
    },
    onRoiUpsert() {
      this.editedRoi = null
      this.$emit("fetch-rois")
    },
  },
}
</script>

<style scoped lang="scss">
.roi-selector {
  display: grid;
  grid-template-rows: 1fr;
  background: white;
  padding: 10px;

  &__add-btn {
    justify-self: end;
  }

  &__item,
  &__headers {
    position: relative;
    display: grid;
    grid-template-columns: 1fr repeat(3, 2fr);
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  &__item {
    cursor: pointer;
    margin: 2px 0px;
    background: #f9f9f9;
    transition: background 0.3s;

    &--selected {
      border: 2px solid #1b77d2;
      background: rgb(232, 244, 253);
    }
  }

  &__headers {
    margin: 5px 0px;
    background-color: rgb(212, 226, 238);
    & > * {
      font-weight: 800;
    }
  }
}
</style>
