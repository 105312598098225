<template>
  <div
    :title="item.label"
    :style="{ ...tooltipWidthStyle, ...tooltipHeightStyle }"
    class="tl-player-image-tooltip cursor-pointer"
  >
    <v-img
      ref="vImg"
      eager
      :src="item.src"
      contain
      :width="calculatedWidth"
      :max-width="calculatedWidth"
      :aspect-ratio="aspectRatio"
      class="tl-player-image-tooltip__img elevation-4"
      @load="calculateAspectRatio"
    >
      <slot></slot>
    </v-img>
    <div
      v-if="showLabel"
      class="tl-player-image-tooltip__label w-100 py-1 pl-3 font-weight-medium"
      :class="{
        'e-bg-gray-900 e-text-white': $vuetify.theme.dark,
        'e-bg-white e-text-gray': !$vuetify.theme.dark,
      }"
      :style="tooltipWidthStyle"
    >
      <div>
        {{ item.label }}
      </div>
      <div v-if="item.timestamp">
        {{ item.timestamp }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"

export default Vue.extend({
  name: "TimelinePlayerImageTooltip",
  props: {
    item: {
      type: Object as PropType<{
        label: string
        src: string
        timestamp: string
      }>,
      required: true,
    },
    width: {
      type: Number,
      default: 120,
    },
    large: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelHeight: {
      type: Number,
      default: 48,
    },
  },

  data() {
    return {
      aspectRatio: 16 / 9,
    }
  },
  computed: {
    calculatedWidth(): number {
      if (this.maxHeight) {
        return Math.min(this.width, this.maxHeight * this.labelHeight)
      }

      return this.width
    },
    maxHeight(): number {
      return this.width / this.aspectRatio
    },
    tooltipWidthStyle(): Record<string, any> {
      return {
        width: `${this.calculatedWidth}px`,
        minWidth: `${this.calculatedWidth}px`,
      }
    },
    tooltipHeightStyle(): Record<string, any> {
      if (!this.large || this.maxHeight) {
        return {
          height: `${
            this.calculatedWidth / this.aspectRatio + this.labelHeight
          }px`,
          minHeight: `${
            this.calculatedWidth / this.aspectRatio + this.labelHeight
          }px`,
        }
      }

      return {
        height: `${this.calculatedWidth / 1.42}px`,
        minHeight: `${this.calculatedWidth / 1.42}px`,
      }
    },
  },
  methods: {
    calculateAspectRatio() {
      const image = this.$refs.vImg?.image as HTMLImageElement
      if (!image) {
        return
      }

      this.aspectRatio = image.naturalWidth / image.naturalHeight
    },
  },
})
</script>

<style lang="scss">
.tl-player-image-tooltip {
  transition: none;
  & * {
    transition: none;
  }
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 0.5em;
  overflow: hidden;
  &__label {
    font-size: small;
  }
}
</style>
