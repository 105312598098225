<template>
  <v-dialog v-model="dialog" persistent max-width="350px">
    <v-card class="roi-form pa-2">
      <v-card-title>
        {{ editedRoi ? "Edit" : "Add" }} Polygon ROI
      </v-card-title>
      <v-card-text class="pa-0">
        <v-text-field v-model="roiName" dense outlined label="Roi Name" />

        <v-menu
          v-if="!editedRoi"
          v-model="isDatePickerOpened"
          :close-on-content-click="false"
          :max-width="290"
          content-class="roi-form__date-menu"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="selectedDate"
              clearable
              readonly
              dense
              outlined
              label="Date"
              v-bind="attrs"
              v-on="on"
              @click:clear="selectedDate = null"
            />
          </template>
          <v-date-picker v-model="selectedDate" class="roi-form__calendar" />
        </v-menu>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="dialog = false">Cancel</v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!isValidForm"
          :loading="isLoading"
          @click="upsertRoi"
        >
          {{ editedRoi ? "Edit" : "Add" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
import { useAccountStore } from "@/stores/account"
import { AiApi } from "@evercam/shared/api/aiApi"
import { RoiType } from "@evercam/shared/types"

export default {
  name: "RoiFormDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedRoi: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      roiName: null,
      isDatePickerOpened: false,
      selectedDate: null,
      isLoading: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    isValidForm() {
      return (
        this.selectedDate &&
        this.$moment(this.selectedDate).isValid() &&
        this.roiName
      )
    },
  },
  watch: {
    dialog: {
      handler(value) {
        if (!value) {
          this.resetFields()

          return
        }

        if (this.editedRoi) {
          this.roiName = this.editedRoi.name
          this.selectedDate = this.editedRoi.fromDate
        }
      },
      immediate: true,
    },
  },
  methods: {
    async upsertRoi() {
      try {
        this.isLoading = true
        const upsertROI = this.editedRoi
          ? AiApi.roi.updateROI
          : AiApi.roi.createROI

        let payload = {}
        if (this.editedRoi) {
          payload = { id: this.editedRoi.id, note: "update roi" }
        } else {
          payload = {
            fromDate: this.selectedDate,
            cameraex: useSiteAnalyticsStore().selectedCamera?.exid,
            shapes: [],
            directionFilter: "left,arrived",
            isactive: true,
            note: "add roi",
          }
        }

        await upsertROI({
          [this.editedRoi ? "updatedBy" : "createdBy"]: useAccountStore().email,
          roi: {
            name: this.roiName,
            roiType: RoiType.SiteAnalytics,
            ...payload,
          },
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
        this.dialog = false
        this.$emit("upsert-roi")
      }
    },
    resetFields() {
      this.roiName = null
      this.selectedDate = null
    },
  },
}
</script>

<style lang="scss">
.roi-form {
  z-index: 9999 !important;
  &__calendar .v-picker__title {
    display: none;
  }
  &__date-menu {
    z-index: 9999 !important;
  }
}
</style>
