<template>
  <div ref="container" v-resize-observer="onResize" class="polygon-roi">
    <div v-if="showHint || showDrawHint" class="polygon-roi__hint">
      <div v-if="showDrawHint" class="d-flex flex-column">
        <span> 🖱️ Click any point to start, then drag to draw. </span>
        <span>⌨️ Press Esc to cancel drawing.</span>
        <span>🖱️ Double-click to finish drawing. </span>
      </div>
      <div v-else class="d-flex flex-column">
        <span v-if="!currentPolygonPoints.length" class="ml-1">
          <v-icon color="white"> fas fa-draw-polygon </v-icon>
          Click the polygon button to start drawing.
        </span>
        <div v-else class="d-flex flex-column ml-1">
          <span v-if="!isSaved">
            <v-icon color="white"> fas fa-save </v-icon>
            Click the save button.
          </span>
          <span>
            <v-icon color="white"> fa-eraser </v-icon>
            Click the eraser button to start redrawing.
          </span>
        </div>
      </div>
      <v-btn icon small @click="dismissHint">
        <v-icon color="white">fas fa-times</v-icon>
      </v-btn>
    </div>
    <svg width="100%" height="100%">
      <polygon
        :points="polygonPointsString"
        fill="rgba(0, 0, 255, 0.3)"
        stroke="blue"
        stroke-width="2"
      />
    </svg>
    <div v-if="!isDrawing" class="polygon-roi__actions">
      <v-btn rounded small color="primary" @click="closeEditMode">
        <v-icon class="mr-1"> fas fa-times </v-icon> close
      </v-btn>
      <v-btn v-if="!isSaved" fab small color="primary" @click="saveDrawing">
        <v-icon> fas fa-save </v-icon>
      </v-btn>
      <v-btn
        v-if="!currentPolygonPoints.length"
        fab
        small
        color="primary"
        @click="startDrawing"
      >
        <v-icon color="white"> fas fa-draw-polygon </v-icon>
      </v-btn>
      <v-btn v-else fab small color="red" @click="removeDrawing">
        <v-icon color="white"> fa-eraser </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { RoiShapeType } from "@evercam/shared/types"

export default {
  name: "PolygonDrawOverlay",
  props: {
    roi: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isDrawing: false,
      currentPolygonPoints: [],
      normalizedPolygonPoints: [],
      polygonPointsString: "",
      rect: null,
      isSaved: true,
      showHint: true,
      showDrawHint: false,
    }
  },
  watch: {
    roi: {
      handler(roi) {
        this.resetDrawing()
        if (roi) {
          this.$nextTick(this.initPolygonPoints)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.rect = this.$refs.container.getBoundingClientRect()
    this.$addEventListener("keydown", this.cancelDrawing)
    this.$addEventListener(
      "dblclick",
      this.onMouseDoubleClick,
      this.$refs.container
    )
  },
  methods: {
    dismissHint() {
      this.showHint = false
    },
    startDrawing() {
      this.resetDrawing()
      this.isDrawing = true
      this.isSaved = false
      this.showDrawHint = true
      this.dismissHint()
      this.$addEventListener(
        "mousedown",
        this.onMouseDown,
        this.$refs.container
      )
      this.$addEventListener(
        "mousemove",
        this.onMouseMove,
        this.$refs.container
      )
      this.$nextTick(() => {
        this.$refs.container.focus()
      })
    },
    endDrawing() {
      this.isDrawing = false
      this.$removeEventListener(
        "mousedown",
        this.onMouseDown,
        this.$refs.container
      )
      this.$removeEventListener(
        "mousemove",
        this.onMouseMove,
        this.$refs.container
      )
      this.showHint = true
    },
    cancelDrawing(event) {
      if (event.key !== "Escape" || !this.isDrawing) {
        return
      }
      this.endDrawing()
      this.resetDrawing()
      this.initPolygonPoints()
    },
    async removeDrawing() {
      const confirmed = await this.$confirmDialog.open({
        title: "Erase ROI Polygon",
        message:
          "Are you sure you want to erase the current ROI polygon and draw a new one?",
      })
      if (!confirmed) {
        return
      }
      this.startDrawing()
    },
    resetDrawing() {
      this.isDrawing = false
      this.showDrawHint = false
      this.isSaved = true
      this.currentPolygonPoints = []
      this.updatePolygon()
    },
    async saveDrawing() {
      const confirmed = await this.$confirmDialog.open({
        title: "Save ROI Polygon",
        message: "Are you sure you want to save the current ROI polygon?",
      })
      if (!confirmed) {
        return
      }
      this.isSaved = true
      this.$emit("update-polygon-points", this.normalizedPolygonPoints)
    },
    async closeEditMode() {
      if (!this.isSaved) {
        await this.saveDrawing()
      }
      this.$emit("close-edit-mode")
    },
    getNormalizedPolygonPoints() {
      return this.currentPolygonPoints.map(([x, y]) => [
        x / this.rect.width,
        y / this.rect.height,
      ])
    },
    initPolygonPoints() {
      const coordinates =
        this.roi.shapes.find((shape) => shape.type === RoiShapeType.Polygon)
          ?.coordinates || []
      this.currentPolygonPoints = coordinates.map(([x, y]) => [
        x * this.rect.width,
        y * this.rect.height,
      ])
      this.updatePolygon()
    },
    onResize() {
      this.rect = this.$refs.container.getBoundingClientRect()
      this.currentPolygonPoints = this.normalizedPolygonPoints.map(([x, y]) => [
        x * this.rect.width,
        y * this.rect.height,
      ])
      this.updatePolygon(false)
    },
    getMousePointer(event) {
      const x = event.clientX - this.rect.left
      const y = event.clientY - this.rect.top

      return [x, y]
    },
    onMouseDoubleClick() {
      if (this.currentPolygonPoints?.length > 2) {
        this.endDrawing()
      }
    },
    onMouseDown(event) {
      if (!this.isDrawing) {
        return
      }
      this.showDrawHint = false
      const point = this.getMousePointer(event)
      this.currentPolygonPoints.push(point)
      this.updatePolygon()
    },
    onMouseMove(event) {
      if (!this.isDrawing || !this.currentPolygonPoints.length) {
        return
      }
      const point = this.getMousePointer(event)
      if (this.currentPolygonPoints.length === 1) {
        this.currentPolygonPoints.push(point)
      } else {
        this.currentPolygonPoints[this.currentPolygonPoints.length - 1] = point
      }
      this.updatePolygon()
    },
    updatePolygon(normalize = true) {
      if (normalize) {
        this.normalizedPolygonPoints = this.getNormalizedPolygonPoints()
      }
      this.polygonPointsString = this.currentPolygonPoints
        .map((point) => point.join(","))
        .join(" ")
    },
  },
}
</script>

<style scoped lang="scss">
.polygon-roi {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 99;

  &__actions {
    position: absolute;
    top: 40px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 99;
    gap: 5px;
  }
  &__hint {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    animation: fade-in 0.3s ease-in-out;

    span {
      margin-right: 10px;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
