import type { TimelineCountEvent, TimelineEvent } from "@evercam/ui"
import type {
  TimelineDateInterval,
  TimelineProviderRequestParams,
  CraneCount,
  CraneLabel,
  CameraExid,
} from "@evercam/shared/types"
import { TimelineDataProvider } from "./timelineDataProvider"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"

export class TimelineCraneActivityProvider extends TimelineDataProvider {
  private static countsPromise: Promise<Array<CraneCount>> | null = null
  readonly cameraExid: CameraExid
  readonly craneLabelFilterFn: (label: CraneLabel) => boolean

  constructor(params: {
    timezone: string
    cameraExid: CameraExid
    craneLabelFilterFn: (label: CraneLabel) => boolean
  }) {
    super(params.timezone)
    this.cameraExid = params.cameraExid
    this.craneLabelFilterFn = params.craneLabelFilterFn || ((_) => true)
  }

  async fetchEvents(_: TimelineDateInterval): Promise<Array<TimelineEvent>> {
    return []
  }

  async fetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineCountEvent>> {
    if (!TimelineCraneActivityProvider.countsPromise) {
      TimelineCraneActivityProvider.countsPromise = this.doFetchCounts(
        params
      ).finally(() => {
        TimelineCraneActivityProvider.countsPromise = null
      })
    }

    return TimelineCraneActivityProvider.countsPromise.then((counts) => {
      return counts.map(({ date, counts }) => ({
        timestamp: this.formatTimestamp(date),
        count: Object.entries(counts).reduce((acc, [label, count]) => {
          if (this.craneLabelFilterFn(label as CraneLabel)) {
            return acc + Number(count)
          } else {
            return acc
          }
        }, 0),
      }))
    })
  }

  private async doFetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<CraneCount>> {
    return await EvercamLabsApi.detections.getCranesMovement({
      cameraExid: this.cameraExid,
      fromDate: params.fromDate,
      toDate: params.toDate,
      precision: params.precision,
    })
  }
}
