import { type TimelineCountEvent, type TimelineEvent } from "@evercam/ui"
import moment from "moment-timezone"
import {
  TimelinePrecision,
  type TimelineProviderRequestParams,
} from "@evercam/shared/types"

export abstract class TimelineDataProvider {
  constructor(protected timezone: string) {}

  public async fetch(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineEvent>> {
    if (params.precision === TimelinePrecision.Events) {
      return this.fetchEvents(params)
    } else {
      return this.fetchCounts(params)
    }
  }

  protected abstract fetchEvents(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineEvent>>

  protected abstract fetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineCountEvent>>

  formatTimestamp(date: string, format: string = ""): string {
    return moment.tz(date, this.timezone).format(format)
  }
}
