<template>
  <div class="detection-label" :class="wrapperClasses">
    <div :style="labelStyles" class="detection-label__content">
      <slot v-bind="{ textColor, backgroundColor }">
        {{ formattedLabel }}
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import * as d3 from "d3"
import { DetectionLabel } from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { camelize } from "humps"
import { stringToColour } from "@evercam/shared/utils.ts"

export default Vue.extend({
  name: "DetectionLabelChip",
  props: {
    label: {
      type: String as PropType<DetectionLabel>,
      required: true,
    },
    dark: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      colors: TimelineColors,
    }
  },
  computed: {
    wrapperClasses(): Record<string, boolean> {
      return {
        "e-bg-gray-900": this.dark,
        "e-bg-gray-50": !this.dark,
      }
    },
    labelStyles(): Record<string, unknown> {
      return {
        background: this.backgroundColor,
        color: this.textColor,
      }
    },
    baseColor(): string {
      return (
        this.colors[camelize(this.label)] ||
        this.colors[this.label] ||
        stringToColour(this.label)
      )
    },
    textColor(): string {
      return (
        this.dark
          ? d3.color(this.baseColor)?.brighter(1.5)
          : d3.color(this.baseColor)?.darker(2)
      )!.toString()
    },
    backgroundColor(): string {
      let color = d3.color(this.baseColor)
      color!.opacity = this.dark ? 0.2 : 0.5

      return color!.toString()
    },
    formattedLabel(): string {
      return this.label
    },
  },
})
</script>

<style scoped lang="scss">
.detection-label {
  display: inline-block;
  border-radius: 0.3em;
  user-select: none;
  word-break: break-word;
  &__content {
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 500;
    line-height: 1.4em;
    padding: 0 0.6em;
    border-radius: 0.3em;
  }
}
</style>
