<template>
  <div class="w-100 h-100">
    <TimelinePlayerWrapper
      v-if="initialFromDate && initialToDate"
      :token="token"
      :dark="dark"
      :project="project"
      :start-date="initialFromDate"
      :end-date="initialToDate"
      :events-groups="timelineEventsGroups"
      :selected-timestamp="selectedTimestamp"
      :selected-camera="selectedCamera"
      :show-thumbnails="showThumbnails"
      :width="width"
      :height="height"
      :timezone="timezone"
      :markers="markers"
      :locked="locked"
      :focused-timestamp="focusedTimestamp"
      :pan-to-focused-timestamp="panToFocusedTimestamp"
      :camera-item-width="cameraItemWidth"
      :hide-timeline="hideTimeline"
      :fit-markers-on-change="fitMarkersOnChange"
      :forbidden-intervals="forbiddenIntervals"
      :focused-interval="focusedInterval"
      :e-timeline-props="eTimelineProps"
      :is-annotation-active="isAnnotationActive"
      :hide-camera-selector="hideCameraSelector"
      :precision-breakpoints="precisionBreakpoints"
      :with-zoom-slider="withZoomSlider"
      :player-start="playerStart"
      :player-end="playerEnd"
      class="h-100 w-100"
      v-bind="$attrs"
      v-on="$listeners"
      @seek="$emit('seek', $event)"
      @visible-interval-change="onDateIntervalChange"
      @event-clicked="$emit('event-clicked', $event)"
      @grouped-events-clicked="$emit('grouped-events-clicked', $event)"
      @marker-clicked="$emit('marker-clicked', $event)"
      @camera-change="$emit('camera-change', $event)"
      @timeline-resized="$emit('timeline-resized', $event)"
      @marker-drag-start="$emit('marker-drag-start', $event)"
      @marker-drag-drag="$emit('marker-drag-drag', $event)"
      @marker-drag-end="$emit('marker-drag-end', $event)"
      @milestone-click="$emit('milestone-click', $event)"
      @timestamp-change="$emit('timestamp-change', $event)"
      @forbidden-timestamp-clicked="
        $emit('forbidden-timestamp-clicked', $event)
      "
      @snapshots-fetched="onSnapshotsFetched"
    >
      <template v-for="(slot, name) in $scopedSlots" #[name]="slotProps">
        <slot :name="name" v-bind="slotProps"></slot>
      </template>

      <template #overlay="{ size }">
        <slot name="overlay" :size="size"></slot>
      </template>

      <template #eventTooltip="{ event, active, type, counts }">
        <slot
          name="eventTooltip"
          :event="event"
          :active="active"
          :type="type"
          :counts="counts"
        >
          <!-- Default tooltip -->
          <TimelinePlayerTooltip
            :key="precision"
            :event="event"
            :active="active"
            :type="type"
            :counts="counts"
            :timezone="timezone"
            :token="token"
          />
        </slot>
      </template>
    </TimelinePlayerWrapper>
  </div>
</template>

<script lang="ts">
import { debounce } from "@evercam/shared/utils"
import type {
  Project,
  Camera,
  TimelineDateInterval,
  TimelineProviderRequestParams,
  TimelinePlayerConfig,
  TimelinePlayerGroupConfig,
  AdminCamera,
} from "@evercam/shared/types"
import type { Snapshot } from "@evercam/shared/types/recording"
import { TaskStatus, TimelinePrecision } from "@evercam/shared/types"
import TimelinePlayerWrapper from "@evercam/shared/components/timelinePlayer/TimelinePlayerWrapper"
import TimelinePlayerTooltip from "@evercam/shared/components/timelinePlayer/TimelinePlayerTooltip"
import {
  TimelineColors,
  TLPlayerDefaultPrecisionBreakpoints,
  TLPlayerDefaultRefreshBreakpoints,
  type TimelinePlayerBreakpoint,
  TLPlayerDefaultChartTypeByPrecision,
} from "@evercam/shared/constants/timeline"
import Vue from "vue"
import type { PropType } from "vue"
import {
  type TimelineChartType,
  type TimelineEvent,
  type TimelineEventsByType,
  type TimelineInterval,
  type TimelineMarker,
} from "@evercam/ui"

export default Vue.extend({
  name: "TimelinePlayer",
  components: {
    TimelinePlayerWrapper,
    TimelinePlayerTooltip,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    eventsGroupsConfig: {
      type: Object as PropType<TimelinePlayerConfig>,
      required: true,
    },
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    fromDate: {
      type: [String],
      default: undefined,
    },
    toDate: {
      type: [String],
      default: undefined,
    },
    token: {
      type: String,
      required: true,
    },
    selectedTimestamp: {
      type: String,
      default: new Date().toISOString(),
    },
    width: {
      type: [Number],
      default: undefined,
    },
    height: {
      type: [Number],
      default: undefined,
    },
    showThumbnails: {
      type: Boolean,
      default: false,
    },
    hideTimeline: {
      type: Boolean,
      default: false,
    },
    markers: {
      type: Array as PropType<TimelineMarker[]>,
      default: () => [],
    },
    focusedTimestamp: {
      type: [String],
      default: undefined,
    },
    cameraItemWidth: {
      type: [Number],
      default: undefined,
    },
    selectedCamera: {
      type: [Object] as PropType<Camera | AdminCamera | undefined>,
      default: undefined,
    },
    fitMarkersOnChange: {
      type: Boolean,
      default: false,
    },
    panToFocusedTimestamp: {
      type: Boolean,
      default: true,
    },
    forbiddenIntervals: {
      type: Array as PropType<TimelineInterval[]>,
      default: () => [],
    },
    focusedInterval: {
      type: [Object, undefined] as PropType<TimelineInterval | undefined>,
      default: undefined,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    hideCameraSelector: {
      type: Boolean,
      default: false,
    },
    isAnnotationActive: {
      type: Boolean,
      default: false,
    },
    eTimelineProps: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
    withZoomSlider: {
      type: Boolean,
      default: true,
    },
    playerStart: {
      type: [String, Date],
      required: true,
    },
    playerEnd: {
      type: [String, Date],
      required: true,
    },
    refreshBreakpoints: {
      type: Array as PropType<TimelinePlayerBreakpoint[]>,
      default: () => TLPlayerDefaultRefreshBreakpoints,
    },
    precisionBreakpoints: {
      type: Array as PropType<TimelinePlayerBreakpoint[]>,
      default: () => TLPlayerDefaultPrecisionBreakpoints,
    },
    chartTypeByPrecision: {
      type: Object as PropType<Record<TimelinePrecision, TimelineChartType>>,
      default: () => TLPlayerDefaultChartTypeByPrecision,
    },
  },
  data() {
    return {
      isInitialized: false,
      isFetchingEvents: false,
      initialFromDate: "",
      initialToDate: "",
      oldFromDate: "",
      oldToDate: "",
      precision: null as TimelinePrecision | null,
      timelineColors: TimelineColors,
      groupsEvents: {} as { [groupName: string]: TimelineEvent[] },
      groupsLoadingState: {} as {
        [groupName: string]: TaskStatus
      },
      groupsVisibility: {} as {
        [groupName: string]: boolean
      },
    }
  },
  computed: {
    projectMinDate(): string {
      return this.project.startedAt ?? this.project.insertedAt ?? ""
    },
    projectMaxDate(): string {
      return new Date(Date.now() + 3600_000).toISOString()
    },
    totalProjectDuration(): number {
      return (
        new Date(this.projectMaxDate).getTime() -
        new Date(this.projectMinDate).getTime()
      )
    },
    defaultStartDate(): string {
      return this.$moment(this.projectMinDate)
        .subtract(this.totalProjectDuration / 6, "milliseconds")
        .toISOString()
    },
    defaultEndDate(): string {
      return this.$moment(this.projectMaxDate)
        .add(this.totalProjectDuration / 6, "milliseconds")
        .toISOString()
    },
    timelineEventsGroups(): TimelineEventsByType {
      let events = Object.entries(this.eventsGroupsConfig).reduce(
        (
          acc,
          [groupName, eventsGroup]: [string, TimelinePlayerGroupConfig]
        ) => {
          const { label, color, provider } = eventsGroup
          const events =
            (provider ? this.groupsEvents[groupName] : eventsGroup.events) || []

          let chartType =
            this.isEventsPrecision ||
            !this.eventsGroupsConfig[groupName].provider
              ? this.eventsGroupsConfig[groupName].chartType
              : this.chartTypeByPrecision[this.precision]

          if (
            typeof this.eventsGroupsConfig[groupName].getChartType ===
            "function"
          ) {
            chartType = this.eventsGroupsConfig[groupName].getChartType(
              this.precision
            )
          }

          return {
            ...acc,
            [groupName]: {
              ...eventsGroup,
              label,
              color,
              events,
              chartType,
              isHidden: !this.groupsVisibility[groupName],
              isLoading:
                this.groupsLoadingState[groupName] === TaskStatus.Loading,
            },
          }
        },
        {}
      ) as TimelineEventsByType
      const { milestones, ...rest } = events

      return { ...rest }
    },
    isEventsPrecision(): boolean {
      return this.precision === TimelinePrecision.Events
    },
    timezone(): string {
      return this.project.timezone || "Europe/Dublin"
    },
  },
  watch: {
    eventsGroupsConfig() {
      this.resetGroupsState()
      this.retrieveEvents(
        {
          fromDate: this.oldFromDate || this.initialFromDate,
          toDate: this.oldToDate || this.initialToDate,
        },
        true
      )
    },
    isEventsPrecision(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.groupsEvents = {}
      }
    },
    precision(v) {
      this.$emit("precision-change", v)
    },
  },
  created() {
    this.init()
  },
  methods: {
    onSnapshotsFetched(snapshots: Snapshot[]) {
      if (!snapshots.length) {
        this.$emit("snapshots-not-found", this.selectedTimestamp)
      }
    },
    init() {
      const { fromDate, toDate } = this.getInitialDateInterval()
      this.initialFromDate = fromDate
      this.initialToDate = toDate
      this.precision = this.calculatePrecision({ fromDate, toDate })

      this.resetGroupsState()
      this.retrieveEvents({
        fromDate: this.initialFromDate,
        toDate: this.initialToDate,
      })
    },
    resetGroupsState() {
      const { groupsEvents, groupsLoadingState, groupsVisibility } =
        Object.entries(this.eventsGroupsConfig).reduce(
          (
            acc,
            [groupName, eventsGroup]: [string, TimelinePlayerGroupConfig]
          ) => {
            return {
              groupsEvents: {
                ...acc.groupsEvents,
                [groupName]: eventsGroup.events || ([] as TimelineEvent[]),
              },
              groupsLoadingState: {
                ...acc.groupsLoadingState,
                [groupName]: TaskStatus.Idle,
              },
              groupsVisibility: {
                ...acc.groupsVisibility,
                [groupName]: true,
              },
            }
          },
          { groupsEvents: {}, groupsLoadingState: {}, groupsVisibility: {} }
        )
      this.groupsEvents = groupsEvents
      this.groupsLoadingState = groupsLoadingState
      this.groupsVisibility = groupsVisibility
    },
    getInitialDateInterval(): TimelineDateInterval {
      let interval: TimelineDateInterval
      if (this.fromDate && this.toDate) {
        interval = {
          fromDate: this.fromDate,
          toDate: this.toDate,
        }
      } else {
        interval = {
          fromDate: this.defaultStartDate,
          toDate: this.defaultEndDate,
        }
      }

      return interval
    },
    calculatePrecision({
      fromDate,
      toDate,
    }: TimelineDateInterval): TimelinePrecision {
      const intervalDuration =
        new Date(toDate).getTime() - new Date(fromDate).getTime()

      return this.precisionBreakpoints.find(({ breakpoint }) => {
        return intervalDuration > breakpoint
      })?.precision as TimelinePrecision
    },
    shouldFetchEvents({ fromDate, toDate }: TimelineDateInterval): boolean {
      if (this.isFetchingEvents || !fromDate || !toDate) {
        return false
      }

      if (!this.oldFromDate || !this.oldToDate) {
        return true
      }

      const oldFromDateMs = new Date(this.oldFromDate).getTime()
      const oldToDateMs = new Date(this.oldToDate).getTime()
      const oldPrecision = this.precision
      const newFromDateMs = new Date(fromDate).getTime()
      const newToDateMs = new Date(toDate).getTime()
      const newPrecision = this.calculatePrecision({
        fromDate,
        toDate,
      })

      if (
        newPrecision === oldPrecision &&
        newFromDateMs >= oldFromDateMs &&
        newToDateMs <= oldToDateMs
      ) {
        return false
      }

      const fromDateDiff = Math.abs(oldFromDateMs - newFromDateMs)
      const toDateDiff = Math.abs(oldToDateMs - newToDateMs)
      const refreshBreakpoint = this.refreshBreakpoints.find(
        ({ precision }) => precision === newPrecision
      )?.breakpoint as number

      return Math.max(fromDateDiff, toDateDiff) > refreshBreakpoint
    },
    async onDateIntervalChange({
      fromDate,
      toDate,
    }: TimelineDateInterval): Promise<void> {
      this.$emit("timeline-interval-change", { fromDate, toDate })

      if (!this.shouldFetchEvents({ fromDate, toDate })) {
        return
      }

      this.debouncedRetrieveEvents({ fromDate, toDate })
    },
    debouncedRetrieveEvents: debounce(function ({ fromDate, toDate }) {
      this.retrieveEvents({ fromDate, toDate })
    }, 1000),
    async retrieveEvents(
      { fromDate, toDate }: TimelineDateInterval,
      force = false
    ): Promise<void> {
      if (!this.shouldFetchEvents({ fromDate, toDate }) && !force) {
        console.log("TimelinePlayer: Skipping redundant fetch.")

        return
      }
      this.isFetchingEvents = true
      this.precision = this.calculatePrecision({ fromDate, toDate })
      const startDate = new Date(fromDate)
      const endDate = new Date(toDate)
      const duration = endDate.getTime() - startDate.getTime()
      const newStartDate = new Date(startDate.getTime() - duration / 5)
      const newEndDate = new Date(endDate.getTime() + duration / 5)

      await this.fetchAllGroupsEvents({
        fromDate: this.$moment(newStartDate).format(),
        toDate: this.$moment(newEndDate).format(),
        precision: this.precision,
      })

      this.isFetchingEvents = false
      this.isInitialized = true
      this.$emit("timeline-initialized", true)
      this.oldFromDate = fromDate
      this.oldToDate = toDate
    },
    async fetchAllGroupsEvents({
      fromDate,
      toDate,
      precision,
    }: TimelineProviderRequestParams): Promise<void[]> {
      const entries: [string, TimelinePlayerGroupConfig][] = Object.entries(
        this.eventsGroupsConfig
      )
      const requests = entries.map(async ([groupName, group]) => {
        if (!group.provider) {
          return
        }
        this.groupsLoadingState[groupName] = TaskStatus.Loading
        try {
          this.groupsEvents[groupName] = await group.provider.fetch({
            fromDate,
            toDate,
            precision,
          })
          this.groupsLoadingState[groupName] = TaskStatus.Idle
        } catch (e) {
          this.groupsLoadingState[groupName] = TaskStatus.Error
        }
      })

      return Promise.all(requests)
    },
  },
})
</script>

<style>
.event-group-bg {
  pointer-events: none;
}
.forbidden-interval {
  cursor: not-allowed;
}
</style>
