<template>
  <div class="gr-tooltip">
    <!-- Thumbnail -->
    <div class="gr-tooltip__thumbnail-container">
      <img
        v-if="!isThumbnailMissing"
        class="gr-tooltip__thumbnail"
        :src="thumbnailUrl"
        alt="event thumbnail"
        @error="isThumbnailMissing = true"
      />
      <div v-else class="gr-tooltip__thumbnail-missing">Thumbnail Missing</div>
    </div>

    <!-- Truck Type -->
    <div class="gr-tooltip__truck-type pt-1">
      <div class="d-flex align-center">
        <ESvgIcon :icon="event.truckType" class="mr-2" size="30" />
        <div>{{ event.truckType }}</div>
      </div>
    </div>

    <!-- Timestamp -->
    <div class="gr-tooltip__timestamp">
      {{ formattedTimestamp }}
    </div>

    <!-- Direction -->
    <div v-if="showEventType" class="gr-tooltip__direction">
      <v-icon class="mr-2">
        fa-{{ event.eventType === "arrived" ? "arrow-down" : "arrow-up" }}
      </v-icon>
      <span>{{ event.eventType === "arrived" ? "In" : "Out" }}</span>
    </div>

    <!-- Camera EXID -->
    <div v-if="showCameraExid" class="gr-tooltip__camera-exid">
      {{ event.cameraExid }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { GateReportEvent } from "@evercam/shared/types"
import { AiApi } from "@evercam/shared/api/aiApi"

export default Vue.extend({
  name: "TimelinePlayerGateReportTooltip",
  props: {
    event: {
      type: Object as PropType<GateReportEvent>,
      required: true,
    },
    showEventType: {
      type: Boolean,
      default: true,
    },
    showCameraName: {
      type: Boolean,
      default: true,
    },
    showCameraExid: {
      type: Boolean,
      default: true,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isThumbnailMissing: false,
    }
  },
  computed: {
    formattedTimestamp(): string {
      return this.$moment
        .tz(this.event.eventTime, this.timezone)
        .format("YYYY-MM-DDTHH:mm:ss")
    },
    thumbnailUrl(): string {
      return AiApi.gateReport.getEventThumbnail({
        cameraExid: this.event.cameraExid,
        eventType: this.event.eventType,
        eventTime: this.event.eventTime,
        token: this.token,
      })
    },
  },
})
</script>

<style lang="scss">
.gr-tooltip {
  display: flex;
  align-items: center;
  gap: 18px;
  color: #333;
  font-weight: 400;
  border-radius: 5px;
  background: rgb(225, 223, 223);
  height: 72px;
  padding-right: 15px;
  min-width: 550px;
  box-shadow: 0 0 17px -10px #000000cf;

  &__thumbnail-container {
    display: inline-block;
    height: 100%;
    box-shadow: 0 0 10px -5px #000;
    background-color: #f0f0f0;
    border-radius: 5px;
    min-width: 64px;
    .gr-tooltip__thumbnail {
      height: 100%;
      border-radius: 3px;
    }
    .gr-tooltip__thumbnail-missing {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }

  &__truck-type,
  &__timestamp,
  &__direction,
  &__camera-name,
  &__camera-exid {
    display: block;
  }
}
</style>
